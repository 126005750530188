import React from 'react'
import { Fade } from 'react-reveal'
import classNames from 'classnames'

import { RowLayout } from '../../../components/layout'
import Page from '../../templates/Page'
import PageSection from '../../templates/PageSection'

import './station-management.css'
import programmingImage from '../../assets/old-images/station-management/notebook.png'
import manageMediaImage from '../../assets/old-images/station-management/manage-media.png'
import playlistImage from '../../assets/old-images/station-management/create-playlist.png'
import autoDJImage from '../../assets/old-images/station-management/autodj.png'
import scheduleImage from '../../assets/old-images/station-management/schedule-events.png'

class StationManagement extends React.Component {
  render() {
    return (
      <Page
        className={classNames('station-management', this.props.className)}
        headerTitle="24/7 Programming Has Never Been Easier"
        headerSubtitle="Always keep your listeners engaged."
        headerContent={headerContent}
      >
        <PageSection className={classNames('manage-media')}>
          <RowLayout
            isResponsive
            breakPoint={1000}
            itemSpacing={'4vw'}
            className={classNames('padded-section-content')}
          >
            <div className={classNames('column')}>
              <Fade top duration={700} fraction={0.9}>
                <h1 className={classNames('section-title')}>Manage Media</h1>
                <p className={classNames('section-text')}>
                  Our web-based media management tool makes it easy to upload
                  new tracks to your library and manage all of your content. Use
                  our tagging system to further organize your station’s content.
                </p>
              </Fade>
            </div>
            <Fade bottom duration={700} fraction={0.9}>
              <img
                src={manageMediaImage}
                alt="Live365 media manager"
                style={{
                  boxShadow: '0px 30px 60px -20px rgba(56, 55, 54, 0.3)'
                }}
              />
            </Fade>
          </RowLayout>
        </PageSection>

        <PageSection className={classNames('create-playlists')}>
          <RowLayout
            isResponsive
            breakPoint={1000}
            itemSpacing={'4vw'}
            className={classNames('reverse-row', 'padded-section-content')}
          >
            <div className={classNames('column')}>
              <Fade top duration={700} fraction={0.9}>
                <h1 className={classNames('section-title')}>
                  Create Playlists
                </h1>
                <p className={classNames('section-text')}>
                  Easily create legal playlists with our drag-and-drop
                  interface.
                </p>
              </Fade>
            </div>
            <Fade bottom duration={700} fraction={0.9}>
              <img
                src={playlistImage}
                alt="Live365 playlist creator"
                style={{
                  boxShadow: '0px 30px 60px -20px rgba(56, 55, 54, 0.3)'
                }}
              />
            </Fade>
          </RowLayout>
        </PageSection>

        <PageSection
          className={classNames(
            'live-dj',
            'light-on-dark',
            'with-background-image'
          )}
        >
          <RowLayout isResponsive breakPoint={1000} itemSpacing={'4vw'}>
            <div className={classNames('column', 'padded-section-content')}>
              <Fade top duration={700} fraction={0.9}>
                <h1 className={classNames('section-title')}>Live DJ</h1>
                <p className={classNames('section-text')}>
                  Broadcast live to your station from anywhere. We support a
                  wide variety of Icecast2 streaming tools.
                </p>
              </Fade>
            </div>
          </RowLayout>
        </PageSection>

        <PageSection className={classNames('auto-dj')}>
          <RowLayout
            isResponsive
            breakPoint={1000}
            itemSpacing={'4vw'}
            className={classNames('padded-section-content')}
          >
            <div className={classNames('column')}>
              <Fade top duration={700} fraction={0.9}>
                <h1 className={classNames('section-title')}>Auto DJ</h1>
                <p className={classNames('section-text')}>
                  The Live365 AutoDJ fills in any gaps in your schedule or you
                  can just let it run to without having to schedule anything.
                </p>
              </Fade>
            </div>
            <Fade bottom duration={700} fraction={0.9}>
              <img
                src={autoDJImage}
                alt="Live365 AutoDJ"
                style={{
                  boxShadow: '0px 30px 60px -20px rgba(56, 55, 54, 0.3)'
                }}
              />
            </Fade>
          </RowLayout>
        </PageSection>

        <PageSection className={classNames('schedule-events')}>
          <RowLayout
            isResponsive
            breakPoint={1000}
            itemSpacing={'4vw'}
            className={classNames('reverse-row', 'padded-section-content')}
          >
            <div className={classNames('column')}>
              <Fade top duration={700} fraction={0.9}>
                <h1 className={classNames('section-title')}>Schedule Events</h1>
                <p className={classNames('section-text')}>
                  Quickly setup an advance schedule of your station’s shows and
                  playlists.
                </p>
              </Fade>
            </div>
            <Fade bottom duration={700} fraction={0.9}>
              <img
                src={scheduleImage}
                alt="Live365 event scheduling"
                style={{
                  boxShadow: '0px 30px 60px -20px rgba(56, 55, 54, 0.3)'
                }}
              />
            </Fade>
          </RowLayout>
        </PageSection>

        {this.props.children}
      </Page>
    )
  }
}

const headerContent = (
  <RowLayout isResponsive itemSpacing={'4vw'}>
    <img src={programmingImage} alt="Programming with Live365" />

    <p className={classNames('body-text')}>
      Between your scheduled shows, live broadcasts and Auto DJ, you can make
      sure your listeners are always able to tune in. We provide you with the
      tools you need to build and manage the station you’ve always wanted.
    </p>
  </RowLayout>
)

export default StationManagement
