import React from 'react'

import SEO from '../../components/SEO'
import Footer from '../../components/Footer'

import PageContent from '../../old/pages/broadcaster/StationManagement'
import NavigationBar from '../../old/components/NavigationBar'
import NextRoute from '../../old/components/NextRoute'
import JoinPrompt from '../../old/components/JoinPrompt'
import Contact from '../../old/components/Contact'
import '../../assets/styles.css'
import '../../old/pages/broadcaster.css'

export default ({ location }) => (
  <React.Fragment>
    <SEO
      title={'Start Your Station - Station Management'}
      pathname={location.pathname}
    />
    <NavigationBar currentPath={location.pathname} />
    <PageContent />
    <JoinPrompt />
    <NextRoute index={3} />
    <Contact />
    <Footer />
  </React.Fragment>
)
